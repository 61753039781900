// require('./_row-fullwidth-container');
// require('./_row-fullwidth');
// require('./_scroll');
// require('./_object-fit');

$(window).on('load resize', setMainPadding);

function setMainPadding() {
    $('a[href$=".pdf"]').attr("target", "_blank");
    $('a[href$=".pdf"]').attr("download","");

    $('a[href$=".jpg"]:not([download]), a[href$=".jpeg"]:not([download]), a[href$=".png"]:not([download]), a[href$=".gif"]:not([download])').fancybox({
        buttons: [
            "zoom",
            "download",
            "close"
        ]
    });

    $('[data-fancybox]').fancybox({
        animationEffect: "zoom",
        afterClose: function () {
            jQuery.fancybox.close();
        },
        iframe: {
            css: {
                width: '1440px'
            }
        }
    });

    jQuery(document).on('gform_confirmation_loaded', function (event, formId) {
        parent.jQuery.fancybox.getInstance().update();
    });

    $('.custom-file-input').on('change', function () {
        //get the file name
        var fileName = $(this)[0].files[0].name;
        //replace the "Choose a file" label
        $(this).next('.custom-file-label').html(fileName);
    })

    var clipboard = new ClipboardJS('[data-clipboard-text]');
    clipboard.on('success', function (e) {
        // console.info('Action:', e.action);
        // console.info('Text:', e.text);
        // console.info('Trigger:', e.trigger);
        var tip = $(e.trigger).tooltip({title: "Link kopiert"}).tooltip('show');
        setTimeout(function() {
            tip.tooltip('dispose');
        }, 800)

        e.clearSelection();
    });
}